import { Link } from '@remix-run/react'

export default function WelcomeWidget() {
	return (
		<>
			<div className="mb-14 w-full border-t-2 pt-4 text-center text-xs font-light uppercase tracking-wider md:hidden">
				<Link to="/" className="font-medium text-theme-yellow">
					Home
				</Link>
			</div>
			<img
				src="https://media.whiletheyweresleeping.com/site/Christina.jpg"
				className="mx-auto aspect-square w-60 rounded-full border-theme-brown-muted shadow-md"
				// width="240px"
				// height="240px"
				alt=""
			/>
			<div className="w-full pb-8 pt-10">
				<h2 className="mb-8 border-b-2 border-theme-yellow pb-2 text-center text-sm font-light uppercase tracking-wider text-theme-brown ">
					Welcome
				</h2>
				<p className="text-center text-xs leading-6 tracking-wider text-gray-700">
					Christina is a writer and mother of five who usually has a book in her
					hands and dirt under her nails. Grab a cup of tea, and come join the
					fun!
				</p>
			</div>
		</>
	)
}
