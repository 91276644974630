import * as React from 'react'
import { useFetcher } from '@remix-run/react'
import { type ActionData } from './types.ts'

function ConvertKitForm({
	formId,
	convertKitTagId,
	convertKitFormId,
}: { formId: string } & (
	| { convertKitTagId?: never; convertKitFormId: string }
	| { convertKitTagId: string; convertKitFormId?: never }
	| { convertKitTagId: string; convertKitFormId: string }
)) {
	const convertKit = useFetcher()
	const formRef = React.useRef<HTMLFormElement>(null)
	const convertKitData: ActionData | null =
		convertKit.state === 'idle' && convertKit.data != null
			? convertKit.data
			: null
	React.useEffect(() => {
		if (formRef.current && convertKitData?.status === 'success') {
			formRef.current.reset()
		}
	}, [convertKitData])

	const success =
		convertKit.state === 'idle' &&
		convertKit.data != null &&
		convertKitData?.status === 'success'
	// React.useEffect(() => {
	//   console.log(convertKitData)
	// }, [convertKitData])

	return (
		<convertKit.Form
			ref={formRef}
			action="/action/convertkit"
			method="POST"
			// noValidate
		>
			{/* <p className="text-xs leading-6 tracking-wider text-left text-gray-700">
        Want to receive email updates?
      </p> */}
			<input type="hidden" name="formId" value={formId} />
			<input type="hidden" name="convertKitTagId" value={convertKitTagId} />
			<input type="hidden" name="convertKitFormId" value={convertKitFormId} />
			<div className="mb-2 flex flex-col text-left">
				<label
					htmlFor="firstName"
					className="text-sm uppercase tracking-widest text-gray-700"
				>
					First Name
				</label>
				<input
					type="text"
					// defaultValue={actionData?.fields?.authorEmail}
					name="firstName"
					id="firstName"
					className="w-full rounded border border-theme-gray p-2 text-sm"
					disabled={convertKit.state !== 'idle' || success}
					aria-invalid={
						Boolean(
							Boolean(
								convertKitData?.status === 'error'
									? convertKitData.errors.firstName
									: null,
							) || undefined,
						) || undefined
					}
					aria-describedby={
						Boolean(
							convertKitData?.status === 'error'
								? convertKitData.errors.firstName
								: null,
						) || undefined
							? 'first-name-error'
							: undefined
					}
				/>
				{convertKitData?.status === 'error' &&
					convertKitData.errors.firstName && (
						<p className="text-sm lowercase text-red-900">
							{convertKitData.errors.firstName}
						</p>
					)}
			</div>
			<div className="mb-2 flex flex-col text-left">
				<label
					htmlFor="email"
					className="text-sm uppercase tracking-widest text-gray-700"
				>
					Email
				</label>
				<input
					type="email"
					// defaultValue={actionData?.fields?.authorEmail}
					name="email"
					id="email"
					className="w-full rounded border border-theme-gray p-2 text-sm"
					disabled={convertKit.state !== 'idle' || success}
					aria-invalid={
						Boolean(
							convertKitData?.status === 'error'
								? convertKitData.errors.email
								: null,
						) || undefined
					}
					aria-describedby={
						Boolean(
							convertKitData?.status === 'error'
								? convertKitData.errors.email
								: null,
						) || undefined
							? 'email-error'
							: undefined
					}
				/>
				{convertKitData?.status === 'error' && convertKitData.errors.email && (
					<p className="text-sm lowercase text-red-900">
						{convertKitData.errors.email}
					</p>
				)}
			</div>
			{success ? (
				<div>Check your email for confirmation.</div>
			) : (
				<div>
					<button
						type="submit"
						className={`more mx-auto w-full border-2 border-theme-yellow px-4 py-2 text-sm font-light uppercase tracking-widest text-theme-yellow hover:text-theme-brown ${
							false && 'cursor-not-allowed opacity-30 hover:text-theme-yellow'
						}`}
					>
						Sign up
					</button>
				</div>
			)}
		</convertKit.Form>
	)
}

export { ConvertKitForm }
