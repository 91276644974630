export default function Sidebar({
	children,
}: {
	children: React.ReactNode | React.ReactNode[]
}) {
	return (
		<div className="flex-1 flex-col items-center justify-center text-center align-middle md:min-w-[200px] md:max-w-[200px] lg:min-w-[300px] lg:max-w-[300px]">
			{children}
		</div>
	)
}
