import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { NavLink } from '@remix-run/react'

const navigation = [
	{ name: 'About', href: '/about', current: true },
	{ name: 'Contact', href: '/contact', current: false },
	{ name: 'Publications', href: '/publications', current: false },
	{ name: 'Library', href: '/library', current: false },
	{ name: 'Posts', href: '/', current: false },
]

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ')
}

export default function NavMenu() {
	return (
		<Disclosure as="nav" className="bg-gray-700">
			{({ open }) => (
				<>
					<div className="font-header mx-auto max-w-7xl px-2 text-sm font-extralight uppercase tracking-widest text-theme-gray sm:px-6 lg:px-8">
						<div className="relative flex h-16 items-center justify-between">
							<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
								{/* Mobile menu button*/}
								<Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
									<span className="sr-only">Open main menu</span>
									{open ? (
										<XMarkIcon className="block h-6 w-6" aria-hidden="true" />
									) : (
										<Bars3Icon className="block h-6 w-6" aria-hidden="true" />
									)}
								</Disclosure.Button>
							</div>
							<div className="hidden w-full flex-1 items-center justify-evenly sm:flex">
								{navigation.map(item => (
									<NavLink
										key={item.name}
										to={item.href}
										className={active => classNames('hover:text-theme-yellow')}
									>
										{item.name}
									</NavLink>
								))}
							</div>
						</div>
					</div>

					<Disclosure.Panel className="font-extralight uppercase tracking-widest text-theme-gray sm:hidden">
						<div className="flex flex-col space-y-3 px-2 pb-3 pt-2 text-2xl">
							{navigation.map(item => (
								<Disclosure.Button
									key={item.name}
									as="a"
									href={item.href}
									className={classNames('hover:text-theme-yellow')}
								>
									{item.name}
								</Disclosure.Button>
							))}
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	)
}
